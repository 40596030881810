import React from 'react'

export default function NotFound() {
  return(
    <span
      className='not-found'>
      {`404: Not Found`}
    </span>
  )
}
